export enum STORAGE_CONSTANTS {
  ACCESS_TOKEN = "token",
  REFRESH_TOKEN = "token_refresh",
  ID_TOKEN = "idToken",
  COUNTRIES = "countries",
  COUNTRIESPHONECODES = "countriesPhoneCodes",
  SIDEBAR_EXPANDED = "sidebarExpanded",
  DRAFT = "draft",
  USER_DRAFT = "userDraft",
  USER_DRAFT_PERMISSIONS = "userPermissionsDraft",
  CONVERSION_DRAFT = "conversionDraft",
  DOCUMENTS_DRAFT = "documentsDraft",
  DOCUMENTS_DOCUMENT_DRAFT = "documentsDocumentDraft",
  WESTEROS_DOCUMENTS_DRAFT = "westerosDocumentsDraft",
  WESTEROS_DOCUMENTS_DOCUMENT_DRAFT = "westerosDocumentsDocumentDraft",
  INVESTMENT_NOTE_TYPE_DRAFT = "investmentNoteTypeDraft",
  WESTEROS_NOTE_TYPE_DRAFT = "westerosNoteTypeDraft",
  INVESTMENT_PRODUCT_FUNDS_DRAFT = "investmentProductFundDraft",
  INVESTMENT_SOURCE_DAFT = "sourceDraft",
  ADVISORY_PROJECT_DRAFT = "advisoryProjectTagDraft",
  INVESTMENT_TAG_DRAFT = "investmentTagDraft",
  WESTEROS_TAG_DRAFT = "westerosTagDraft",
  WESTEROS_SERVICE_DRAFT = "westerosServiceDraft",
  CAPITAL_MOVEMENT_DIVIDEND_DRAFT = "capitalMovementDividendDraft",
  CAPITAL_MOVEMENT_DRAFT = "capitalMovementDraft",
  CAPITAL_MOVEMENT_DOCS_DRAFT = "capitalMovementDocsDraft",
  CAPITAL_MOVEMENT_CHILDREN_DATAS_DRAFT = "capitalMovementChildrenDataDraft",
  CAPITAL_MOVEMENT_INPUTS_DATA_DRAFT = "capitalMovementInputsDataDraft",
  CAPITAL_MOVEMENT_DYNAMIC_FORM_DRAFT = "capitalMovementDynamicFormDraft",
  WESTEROS_CLIENTS_DRAFT = "westerosClientsDraft",
  WESTEROS_CLIENTS_TAGS_DRAFT = "westerosClientsTagsDraft",
  WESTEROS_CLIENTS_NOTES_DRAFT = "westerosClientsNotesDraft",
  WESTEROS_CLIENTS_DOC_LIST_DOCS_DRAFT = "westerosClientsDocListDocsDraft",
  WESTEROS_CLIENTS_ADDITIONAL_DOCS_DRAFT = "westerosClientsAdditionalDocsDraft",
  WESTEROS_CLIENTS_UBOS_DRAFT = "westerosClientsUbosDraft",
  WESTEROS_CLIENT_LIQUIDITIES_DRAFT = "westerosClientLiquidityDraft",
  WESTEROS_CLIENT_ADVISORY_TOKENS_DRAFT = "westerosClientAdvisoryTokensDraft",
  ADVISORY_BUYER_FORM_DRAFT = "westerosAdvisoryBuyerFormDraft",
  ADVISORY_BUYER_WALLET_FORM_DRAFT = "westerosAdvisoryBuyerWalletFormDraft",
  ADVISORY_PURCHASE_FORM_DRAFT = "westerosAdvisoryPurchaseFormDraft",
  ADVISORY_TRANCHE_FORM_DRAFT = "westerosAdvisoryTrancheFormDraft",
  ADVISORY_EXIT_PLAN_FORM_DRAFT = "westerosAdvisoryExitPlanFormDraft",
  ADVISORY_EXIT_PLAN_EXIT_FORM_DRAFT = "westerosAdvisoryExitPlanExitFormDraft",
  ADVISORY_DISTRIBUTION_FORM_DRAFT = "westerosAdvisoryDistributionFormDraft",
  LINK_AGENT_FORM_DRAFT = "linkAgentFormDraft",
  LINK_AGENT_DOCUMENTS_DRAFT = "linkAgentDocumentsDraft",
  LINK_AGENT_NOTES_DRAFT = "linkAgentNotesDraft",
  WEEKLY_UPDATES_DOCS_DRAFT = "weeklyUpdatesDocsDraft",
  WEEKLY_UPDATES_FORM_DRAFT = "weeklyUpdatesFormDraft",
  ANNOUNCEMENTS_DOCS_DRAFT = "announcementsDocsDraft",
  ANNOUNCEMENTS_FORM_DRAFT = "announcementsFormDraft",
  UPCOMING_EVENTS_DOCS_DRAFT = "upcomingEventsDocsDraft",
  UPCOMING_EVENTS_FORM_DRAFT = "upcomingEventsFormDraft",
  STATEMENT_FORM_DRAFT = "statementFormDraft",
  STATEMENT_DOCUMENT_DRAFT = "statementDocumentDraft",
  REPORTS_FORM_DRAFT = "reportFormDraft",
  REPORTS_DOC_DRAFT = "reportDocumentDraft",
  LINK_AGENT_PAYOUT_DRAFT = "linkAgentPayoutDraft",
  LINK_AGENT_PAYOUT_DOCUMENT_DRAFT = "linkAgentPayoutDocsDraft",
  VENTURE_NOTE_TYPE_FORM_DRAFT = "ventureNoteTypeFormDraft",
  VENTURE_TAG_FORM_DRAFT = "ventureTagFormDraft",
  VENTURE_DOCUMENTS_DOCUMENT_DRAFT = "ventureDocumentsDocumentDraft",
  VENTURE_DOCUMENTS_DRAFT = "ventureDocumentsDraft",
  VENTURE_SECTOR_FORM_DRAFT = "ventureSectorFormDraft",
  RESOURCE_FORM_DRAFT = "resourceFormDraft",
  RESOURCE_DOCUMENTS_DRAFT = "resourceDocumentsDraft",
  EMAIL_TEMPLATE_FORM_DRAFT = "emailTemplateFormDraft",
  ORIGINATION_SOURCE_FORM_DRAFT = "originationSourceFormDraft",
  VENTURE_FUND_FORM_DRAFT = "ventureFundFormDraft",
  VENTURE_BLOCKCHAIN_FORM_DRAFT = "ventureBlockchainFormDraft",
  VENTURE_PROJECT_TYPE_FORM_DRAFT = "ventureProjectTypeFormDraft",
  VENTURE_INVESTMENT_METHOD_FORM_DRAFT = "ventureInvestmentMethodFormDraft",
  VENTURE_PROJECT_PROFILE_FORM_DRAFT = "ventureProjectProfileFormDraft",
  VENTURE_PROJECT_PROFILE_TAGS_DRAFT = "ventureProjectProfileTagsDraft",
  VENTURE_PROJECT_PROFILE_NOTES_DRAFT = "ventureProjectProfileNotesDraft",
  VENTURE_PROJECT_PROFILE_ADDITIONAL_DOCS_DRAFT = "ventureProjectProfileAdditionalDocsDraft",
  VENTURE_PROJECT_PROFILE_DOC_LIST_DOCS_DRAFT = "ventureProjectProfileDocListsDocsDraft",
  VENTURE_PROJECT_PROFILE_FOUNDER_CONTACT_DRAFT = "ventureProjectProfileFounderContactsDraft",
  VENTURE_PROJECT_PROFILE_DUE_DILIGENCE_DOCUMENTS_DRAFT = "ventureProjectProfileDueDiligenceDocumentsDraft",
  VENTURE_CONTACT_TYPE_FORM_DRAFT = "ventureContactTypeFormDraft",
  VENTURE_VALUATION_CONTROL_FORM_DRAFT = "ventureValuationControlFormDraft",
  VENTURE_CONTACT_FORM_DRAFT = "ventureContactFormDraft",
  ASSET_ATTRIBUTION_INVESTMETNS_DRAFT = "assetAttributionInvestmentsDraft",
  ASSET_ATTRIBUTION_TRANSFER_SOURCES_DRAFT = "assetAttributionTransferSourcesDraft",
  ASSET_ATTRIBUTION_EXITS_DRAFT = "assetAttributionExitsDraft",
  SYSTEM_TASK_MANAGEMENT_TASK_TYPE_DRAFT = "systemTaskManagementTaskTypeDraft",
  SYSTEM_TASK_MANAGEMENT_TASK_DRAFT = "systemTaskManagementTaskDraft",
  SYSTEM_TASK_MANAGEMENT_NOTE_TYPE_DRAFT = "systemTaskManagementNoteTypeDraft",
  SYSTEM_TASK_MANAGEMENT_TASK_NOTE_DRAFT = "systemTaskManagementTaskNoteDraft",
  SYSTEM_TASK_MANAGEMENT_RECURRING_TASK = "systemTaskManagementRecurringTask",
  FIREBLOCKS_WALLET_FORM_DRAFT = "fireblocksWalletFormDraft",
}

export enum QUERY_STRINGS {
  SORT = "sort",
  PAGINATION = "page",
  SEARCH = "search",
  LIMIT_PER_PAGE = "limit",
  FILTERS = "filters",
  GROUP_BY = "group_by",
  SEPARATOR_COMMA = ",",
  SEPARATOR = ":",
  ID_NAME = "idName",
}

export enum DELAY_VALUE_FOR_DEBOUNCE {
  BASIC_DEBOUNCE = 500,
  TABLE_SEARCH_DEBOUNCE = 500,
  DOCUMENT_DESCRIPTION_DEBOUNCE = 500,
  FILTERS_DEBOUNCE = 1000,
  SLIDE_ANIMATION = 40,
}

export enum DELAY_VALUE_FOR_UNMOUNT {
  SELECTIVE_INPUT_DELAY = 196,
  FILTERBAR_DELAY = 225,
}

export enum PAGINATION_RELATED_CONSTANTS {
  DEFAULT_PAGE = 1,
}

export enum SocialNetworks {
  TELEGRAM = "telegram",
  LINE = "line",
  WHATSAPP = "whatsapp",
  TWITTER = "twitter",
  DISCORD = "discord",
  SLACK = "slack",
  LINKEDIN = "linkedin",
  VIBER = "viber",
  SKYPE = "skype",
  FACEBOOK = "facebook",
  GIT = "git",
}

export enum ScopeEnum {
  Create = "CREATE",
  Delete = "DELETE",
  SendEmail = "SEND_EMAIL",
  Update = "UPDATE",
  View = "VIEW",
  Archive = "ARCHIVE",
}

export enum TaskStatusEnum {
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  IN_REVIEW = "Review Needed",
  BLOCKED = "Blocked",
  DECLINED = "Declined",
  COMPLETED = "Completed",
}

export enum ResourceEnum {
  SuperAdmin = "SUPER_ADMIN",
  AdvisoryBuyer = "ADVISORY_BUYER",
  AdvisoryProject = "ADVISORY_PROJECT",
  AdvisoryPurchase = "ADVISORY_PURCHASE",
  AdvisoryTranche = "ADVISORY_TRANCHE",
  AdvisoryExitPlan = "ADVISORY_EXIT_PLAN",
  AdvisoryDistribution = "ADVISORY_DISTRIBUTION",
  AdvisoryReport = "ADVISORY_REPORT",
  Announcement = "ANNOUNCEMENT",
  AnnouncementDocument = "ANNOUNCEMENT_DOCUMENT",
  AssetsAsset = "ASSETS_ASSET",
  AssetsExchange = "ASSETS_EXCHANGE",
  AssetsFireblocks = "ASSETS_FIREBLOCKS",
  CapitalMovement = "CAPITAL_MOVEMENT",
  CapitalMovementSwitching = "CAPITAL_MOVEMENT_SWITCHING",
  Country = "COUNTRY",
  CountryPhoneCode = "COUNTRY_PHONE_CODE",
  DigitalAssetConcern = "DIGITAL_ASSET_CONCERN",
  DocumentList = "DOCUMENT_LIST",
  EmailHistory = "EMAIL_HISTORY",
  EmailTemplate = "EMAIL_TEMPLATE",
  EmployeeDashboard = "EMPLOYEE_DASHBOARD",
  ExposureMotivation = "EXPOSURE_MOTIVATION",
  InvestmentCapacity = "INVESTMENT_CAPACITY",
  InvestmentData = "INVESTMENT_DATA",
  InvestmentType = "INVESTMENT_TYPE",
  InvestorData = "INVESTOR_DATA",
  InvestorDocument = "INVESTOR_DOCUMENT",
  InvestorIntakeInterview = "INVESTOR_INTAKE_INTERVIEW",
  InvestorNote = "INVESTOR_NOTE",
  InvestorProfile = "INVESTOR_PROFILE",
  KpiData = "KPI_DATA",
  LeadConversionExpectation = "LEAD_CONVERSION_EXPECTATION",
  LinkAgentDocument = "LINK_AGENT_DOCUMENT",
  LinkAgentKpiData = "LINK_AGENT_KPI_DATA",
  LinkAgentNote = "LINK_AGENT_NOTE",
  LinkAgentPayout = "LINK_AGENT_PAYOUT",
  LinkAgentPayoutDocument = "LINK_AGENT_PAYOUT_DOCUMENT",
  LinkAgentProfile = "LINK_AGENT_PROFILE",
  LinkAgentReport = "LINK_AGENT_REPORT",
  NoteType = "NOTE_TYPE",
  Notification = "NOTIFICATION",
  Permission = "PERMISSION",
  ProductFund = "PRODUCT_FUND",
  ProductFundSeries = "PRODUCT_FUND_SERIES",
  ProjectProfile = "PROJECT_PROFILE",
  Report = "REPORT",
  ReportType = "REPORT_TYPE",
  Resource = "RESOURCE",
  ResourceDocument = "RESOURCE_DOCUMENT",
  Role = "ROLE",
  Snapshot = "SNAPSHOT",
  SourceExchange = "SOURCE_EXCHANGE",
  SourceOfInvestment = "SOURCE_OF_INVESTMENT",
  Statement = "STATEMENT",
  StatementType = "STATEMENT_TYPE",
  SystemTaskManagementTask = "SYSTEM_TASK_MANAGEMENT_TASK",
  SystemTaskManagementTaskType = "SYSTEM_TASK_MANAGEMENT_TASK_TYPE",
  SystemTaskManagementTaskTemplate = "SYSTEM_TASK_MANAGEMENT_TASK_TEMPLATE",
  SystemTaskManagementTaskNoteType = "SYSTEM_TASK_MANAGEMENT_TASK_NOTE_TYPE",
  SystemTaskManagementTaskDocument = "SYSTEM_TASK_MANAGEMENT_TASK_DOCUMENT",
  SystemTaskManagementTaskNote = "SYSTEM_TASK_MANAGEMENT_TASK_NOTE",
  SystemTaskManagementRecurringTask = "SYSTEM_TASK_MANAGEMENT_RECURRING_TASK",
  SystemTaskManagementTaskReporting = "SYSTEM_TASK_MANAGEMENT_TASK_REPORTING",
  Tag = "TAG",
  Token = "TOKEN",
  UpcomingEvent = "UPCOMING_EVENT",
  UpcomingEventDocument = "UPCOMING_EVENT_DOCUMENT",
  User = "USER",
  UserDepartment = "USER_DEPARTMENT",
  VentureBlockchain = "VENTURE_BLOCKCHAIN",
  VentureContactType = "VENTURE_CONTACT_TYPE",
  VentureDocument = "VENTURE_DOCUMENT",
  VentureDocumentList = "VENTURE_DOCUMENT_LIST",
  VentureInvestmentMethod = "VENTURE_INVESTMENT_METHOD",
  VentureNote = "VENTURE_NOTE",
  VentureNoteType = "VENTURE_NOTE_TYPE",
  VentureOriginationSource = "VENTURE_ORIGINATION_SOURCE",
  VentureProjectType = "VENTURE_PROJECT_TYPE",
  VentureSector = "VENTURE_SECTOR",
  VentureTag = "VENTURE_TAG",
  VentureDashboard = "VENTURE_DASHBOARD",
  VentureValuationControl = "VENTURE_VALUATION_CONTROL",
  VentureAssetAttribution = "ASSET_ATTRIBUTION",
  VentureContact = "VENTURE_CONTACT",
  VentureKpi = "VENTURE_KPI",
  VenturePortfolioControl = "VENTURE_PORTFOLIO_CONTROL",
  VentureSnapshots = "VENTURE_SNAPSHOT",
  WeeklyUpdate = "WEEKLY_UPDATE",
  WeeklyUpdateDocument = "WEEKLY_UPDATE_DOCUMENT",
  WesterosClientDocument = "WESTEROS_CLIENT_DOCUMENT",
  WesterosClientNote = "WESTEROS_CLIENT_NOTE",
  WesterosClientProfile = "WESTEROS_CLIENT_PROFILE",
  WesterosDocumentList = "WESTEROS_DOCUMENT_LIST",
  WesterosNoteType = "WESTEROS_NOTE_TYPE",
  WesterosService = "WESTEROS_SERVICE",
  WesterosTag = "WESTEROS_TAG",
  FireblocksWallet = "FIREBLOCKS_WALLET",
  CapitalMovementDividend = "CAPITAL_MOVEMENT_DIVIDEND",
}

export enum InvestorTypeEnum {
  Corporate = "Corporate",
  Individual = "Individual",
  Institution = "Institutional",
}

export enum InvestorStatusEnum {
  AI = "Active Investor",
  CS = "Commitment Secured",
  DIP = "Documentation in Process",
  DOC = "Documentation Complete",
  EI = "Exited Investor",
  IN = "Inactive",
  IP = "Investor Profile Complete",
  IPC = "Initial Presentation Complete",
  NL = "New Lead",
  P = "Prospect",
  PPM = "Post-Presentation Meeting Complete",
}

export enum InvestmentStatusEnum {
  COMMITTED = "Committed",
  INVESTMENT_PENDING = "Investment pending",
  INVESTMENT_SECURED = "Investment secured",
  UNCOMMITTED = "Uncommitted",
}

export enum InvestorClassEnum {
  CORPORATION = "Corporation",
  FAMILY_OFFICE = "Family Office",
  INDIVIDUAL = "Individual",
  INSTITUTION = "Institution",
}

export enum StorageEnum {
  TMP = "tmp",
  GENERAL = "general",
  BACKUP = "backup",
  ETERNAL = "eternal",
}

export enum CapitalMovementTypeEnum {
  ADDON_SUBSCRIPTION = "Add-On Subscription",
  DIVIDEND_INVESTMENT_SUBSCRIPTION = "Dividend Investment Subscription",
  DIVIDEND_PAYOUT = "Dividend Payout",
  DISTRIBUTION = "Distribution",
  FTX_SIDE_POCKET = "FTX Side Pocket",
  INITIAL_SUBSCRIPTION = "Initial Subscription",
  REDEMPTION = "Redemption",
  FTX_SIDE_POCKET_REDEMPTION = "Redemption from FTX Side Pocket",
  REDEMPTION_SIDE_POCKET = "Redemption due to Side Pocket",
  SERIES_SWITCHES = "Series switches",
  // subscription not included in Total Subscription calculation
  SUBSCRIPTION = "Subscription",
}

export enum InvestmentTypeEnum {
  FIAT = "Fiat",
  IN_KIND = "InKind",
}

export enum StatusPalette {
  NEW_LEAD = "#BAE4FF",
  PROSPECT = "#7CCBFF",
  IP = "#319DE3",
  IC = "#2576AB",
  PPM = "#1D5980",
  CS = "#154463",
  COMMITTED = "#0E2C40",
  ACTIVE = "#06121B",
}

export enum ArtificialDelay {
  CHART_BE_RESPONSE = 500,
  REPORTING_EXPORT = 0,
  FILE_DOWNLOAD_TIMEOUT = 3000,
  TILL_EXPAND_COLLAPSIBLE_SECTIONS = 1000,
  MODAL_SWITCH = 100,
  SEND_EMAIL_FILTER_DELETE = 1000,
}

export enum SnapshotTypeEnum {
  EXCHANGE = "Exchange",
  FIREBLOCKS = "Fireblocks",
}

export enum ClientStatusEnum {
  CLIENT_ACTIVE = "Client active",
  CLIENT_DOCUMENTATION = "Client documentation",
  CLIENT_INACTIVE = "Client inactive",
  CLIENT_ONBOARDING = "Client onboarding",
}

export enum LiquidSupplyEnum {
  LESS_TEN_PERCENT = "Less 10%",
  TEN_PERCENT = "10%",
  TWENTY_PERCENT = "20%",
  MORE_THIRTY_PERCENT = "More 30%",
  UNDECIDED = "Undecided",
}

export enum SourceExchangeTypeEnum {
  BANK = "Bank",
  CUSTODY = "Custody",
  EXCHANGE_CEX = "Exchange-CEX",
  EXCHANGE_DEX = "Exchange-DEX",
  WALLET = "Wallet",
}

export enum ProductFundDividendFrequencyEnum {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}

export enum NotificationTypeEnum {
  ANNOUNCEMENT_ADDED = "Announcement added",
  CAPITAL_MOVEMENT_ADDED = "Capital movement added",
  INVESTOR_UPDATED = "Investor updated",
  INVESTOR_DOCUMENT_UPDATED = "Investor document updated",
  REPORT_ADDED = "Report added",
  STATEMENT_ADDED = "Statement added",
  TASK_CREATED = "Task Created",
  TASK_UPDATED = "Task Updated",
  TASK_DELETED = "Task Deleted",
  TASK_NOTE_CREATED = "Task Note Created",
  TASK_NOTE_UPDATED = "Task Note Updated",
  UPCOMING_EVENT_ADDED = "Upcoming event added",
  WEEKLY_UPDATE_ADDED = "Weekly update added",
}

export enum EventEnum {
  NOTIFICATION = "notification",
  ERROR = "error",
}

export enum LinkAgentPayoutInvestmentStatusEnum {
  Paid = "PAID",
  Pending = "PENDING",
}

export enum CommissionStatusEnum {
  NOT_SEASONED = "Not seasoned",
  PENDING = "Pending",
  PAID = "Paid",
}

export enum PaymentTypeEnum {
  FIAT = "Fiat",
  IN_KIND = "In kind",
}

export enum CapitalTrackingStatusEnum {
  AI = "Active investor",
  EI = "Exited investor",
  PROSPECT = "Prospect",
}

export enum UserInteractionTypeEnum {
  INVESTOR = "Investor",
  INTERNAL = "Internal",
}

export enum DepartmentEnum {
  ADMINISTRATION = "Administration",
  CAPITAL_FORMATION = "Capital Formation",
  DEFI = "DeFi",
  FINANCE = "Finance",
  IT_DEVELOPMENT = "IT & Development",
  MANAGEMENT = "Management",
  QUANTITATIVE = "Quantitative",
  SERVICE_PROVIDER = "Service Provider",
  VENTURE = "Venture",
}

export enum RecurringTaskScheduleEnum {
  DAY = "Daily",
  WEEK = "Weekly",
  MONTH = "Monthly",
  QUARTER = "Quarterly",
  YEAR = "Yearly",
}
