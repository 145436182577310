import {
  VentureSnapshotDownloadDocument,
  VentureSnapshotsPaginatedDocument,
  VentureSnapshotTypeEnumListDocument,
} from "api/generated";
import { Optional } from "config";

import {
  IVentureSnapshotsListVars,
  IVentureSnapshotListReturnType,
} from "./types";
import { RequestTypes } from "../../enums";
import { getRequestInstance } from "../../utils";

export async function getVentureSnapshotTypes(): Promise<
  { id: number; name: string }[]
> {
  const makeRequest = getRequestInstance(RequestTypes.QUERY);
  const res = await makeRequest({
    query: VentureSnapshotTypeEnumListDocument,
  });

  return res?.data?.ventureSnapshotTypeEnumList ?? [];
}

export async function getVentureSnapshotsList(
  variables: IVentureSnapshotsListVars
): Promise<IVentureSnapshotListReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: VentureSnapshotsPaginatedDocument,
      variables,
    });
    return res?.data?.ventureSnapshotsPaginated;
  } catch (error) {
    throw error;
  }
}

export async function downloadVentureSnapshotDocument(
  ventureSnapshotId: number
): Promise<Optional<string>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: VentureSnapshotDownloadDocument,
      variables: {
        ventureSnapshotId,
      },
    });
    return res?.data?.ventureSnapshotDownload;
  } catch (error) {
    throw error;
  }
}
