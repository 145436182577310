import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getVentureSnapshotsList, IVentureSnapshotListReturnType } from "api";
import { Nullable } from "config";
import { RootState } from "store/store";

const initialState = {
  data: null as Nullable<IVentureSnapshotListReturnType["edges"]>,
  totalCount: 1,
  loading: false,
};

export const fetchVentureSnapshotsData = createAsyncThunk(
  "venture-snapshots/fetchVentureSnapshotsData",
  async (_, { getState }) => {
    try {
      const filters = (getState() as RootState).filters;
      if (!Object.values(filters).length) {
        return;
      }
      const response = await getVentureSnapshotsList(filters);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const VentureSnapshotsSlice = createSlice({
  name: "venture-snapshots",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVentureSnapshotsData.fulfilled, (state, action) => {
      if (!action.payload) return;
      return {
        ...state,
        data: action.payload?.edges as IVentureSnapshotListReturnType["edges"],
        totalCount: action.payload?.totalCount,
        loading: false,
      };
    });
    builder.addCase(fetchVentureSnapshotsData.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchVentureSnapshotsData.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
  },
});

export default VentureSnapshotsSlice.reducer;
